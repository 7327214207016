const Inter = [
  {
    space_src: "baidujs",
    space_type: "inters",
    space_id: "u6965388",
    space_js: "//h5fpb1.sdlads.cn/source/xsra/resource/r/pk/static/u-u.js"
  }
];

const Native1 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6965390",
    space_js: "//h5fpb1.sdlads.cn/source/ql/source/kt/production/ki-dt-r.js"
  }
];

const Native2 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6965391",
    space_js: "//h5fpb1.sdlads.cn/source/r/static/ml/common/u-ljeu-v.js"
  }
];

const Native3 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6965392",
    space_js: "//h5fpb1.sdlads.cn/source/sn_m_vmk_f/source/vc.js"
  }
];

const Banner = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6965389",
    space_js: "//h5fpb1.sdlads.cn/site/y/t/source/sb/sqlv_b.js"
  }
];

export default {
  Home_inter: Inter,
  Home_native: Native1,
  Home_banner: Banner,
  Detail_inter: Inter,
  Detail_native: Native1,
  Detail_banner: Banner,
  List_native: Native1,
  List_banner: Banner,
  Result_inter: Inter,
  Result_native: Native1,
  Result_banner: Banner,
  Result_native_center1: Native2,
  Result_native_center2: Native3,
};
